footer {
    // border: 1px solid red;
    background: #262A3A;
    height: 5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
}
