.sections {
    min-height: 40rem;
    margin-bottom: 10rem;
    .section-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .crud {
            margin-bottom: 3rem;
        }
    }
    .items {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    background-color: white;

    span {
        font-weight: 800;
        font-size: large;
    }

    button {
        background-color: rgb(38, 42, 58);
        height: 2rem;
        border-radius: 5px;
        color: white;
    }

    .delete{
        background-color: rgb(201, 0, 0) !important;
    }

    .update{
        background-color: rgb(0, 127, 201) !important;
    }

    &-container {
        padding-top: 3rem;
        padding-bottom: 2.5rem;
        width: 50%;
        box-shadow: -1px 4px 18px 0px rgba(0, 0, 0, 0.25);

        .title {
            display: flex;
            justify-content: center;
        }

        .imageButtons {
            display: flex;
            justify-content: center;
        }

        .navButtons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .nav {
                text-decoration: none;
                margin-left: 10rem;
                margin-bottom: 2rem;
            }
        }
    }
    img {
        height: 20rem;
        width: 20rem;
    }
}
