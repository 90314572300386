header{
    background-color: #282C34;
    height: 5rem;
    padding-top: 3rem;
    position: relative;

    nav{
        // background-color: wheat;
        display: flex;   
        justify-content: center;
        
        .item{
            margin-right: 3rem;
        }
    }

    .links{
        text-decoration: none;
        color: white;
        font-size: large;
        font-weight: 500;
    }
}