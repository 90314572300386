.lessons {
    // display: flex;
    margin-top: 5rem;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding-bottom: 10rem;

    button{
        margin-right: 1rem;
    }

    // img {
    //     height: 20rem;
    //     width: 20rem;
    // }
}
