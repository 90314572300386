.ReactCrop__image{
    width: 600px;
}

.imageContainer{
    display: flex;
}

.preview{
    margin-left: 5rem;
}